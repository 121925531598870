import { HttpClient, RequestParams } from "../http-client/http-client"
import { LoginRequest } from "../models/auth/requests"
import { LoginResponse } from "../models/auth/resposes"

class AuthService<SecurityDataType = unknown> {
    http: HttpClient<SecurityDataType>

    constructor(http: HttpClient<SecurityDataType>) {
        this.http = http
    }

    userLogin = (data: LoginRequest, params?: RequestParams) =>
        this.http.request<LoginResponse>({
            path: `/Auth/Login`,
            method: 'POST',
            secure: false,
            body: data,
            ...params,
        })


    DashboardLogin = (data: LoginRequest, params?: RequestParams) =>
        this.http.request<LoginResponse>({
            path: `/Dashboard/Auth/Login`,
            method: 'POST',
            secure: false,
            body: data,
            ...params,
        })
}

export default AuthService
