/* eslint-disable react-hooks/exhaustive-deps */
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import { Tag } from 'antd'
import { Fragment, ReactNode, useEffect, useState } from 'react'

interface Status {
  value: any
  color: string
  text?: string
  icon?: ReactNode
}

type Props =
  | {
      value?: boolean
      trueTitle?: string
      falseTitle?: string
      boolean: true
    }
  | {
      statuses: Status[]
      value?: any
      boolean?: false
    }

const StatusTag: React.FC<Props> = (props) => {
  const [status, setStatus] = useState<Status>()

  useEffect(() => {
    const data: Status[] = props.boolean
      ? [
          { value: true, color: 'green', text: props.trueTitle },
          { value: false, color: 'red', text: props.falseTitle },
        ]
      : props.statuses ?? []

    setStatus(data?.find((s) => s.value === props.value))
  }, [props.value])

  return (
    <Fragment>
      {props.boolean ? (
        props.value ? (
          <CheckCircleTwoTone twoToneColor='green' style={{ fontSize: 20 }} />
        ) : (
          <CloseCircleTwoTone twoToneColor='red' style={{ fontSize: 20 }} />
        )
      ) : (
        <Tag color={status?.color} title={status?.text}>
          {status?.icon} {status?.text}
        </Tag>
      )}
    </Fragment>
  )
}

export default StatusTag
