import {
  Descriptions as AntdDescriptions,
  Card,
  Divider,
  Statistic,
  Typography,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { GoldTwoTone } from '@ant-design/icons'

const { Title } = Typography

interface Props {
  title: string
  value: number
  currency?: string
  currencySymbol?: string
}

const GoldRateCard: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Card bordered={false} bodyStyle={{ padding: 12 }}>
      <Statistic
        title={
          <Title level={1} style={{ margin: 0 }}>
            <GoldTwoTone twoToneColor='#d4af37' />
            {props.title}
          </Title>
        }
        value={props.value}
        precision={3}
        // prefix={<GoldTwoTone twoToneColor='#d4af3' />}
        suffix={<Title level={4}>{t(props.currency ?? 'USD')}</Title>}
        valueStyle={{
          fontSize: 44,
          fontWeight: 'bold',
          color: '#d4af37',
        }}
        prefix={
          <Title
            level={1}
            style={{
              margin: 0,
              fontSize: 44,
            }}
          >
            {props.currencySymbol}
          </Title>
        }
      />
    </Card>
  )
}

export default GoldRateCard
