import { createContext } from 'react'
import { DEFAULT_FUNCTION } from '../../utils/helpers/constants'
import { IContext } from '../base/context'
import { CompanyProfileDetailsResponse } from '../../api/models/company-profile/responses'
import {
  CompanyProfileRequest,
  MyCompanyProfileRequest,
} from '../../api/models/company-profile/requests'
import { GoldRateResponse } from '../../api/models/gold-rate/responses'

export type MyCompanyProfileLoading =
  | 'details'
  | 'update'
  | 'upload-file'
  | 'delete-file'

export interface IContextState {
  loading: MyCompanyProfileLoading[]

  details?: CompanyProfileDetailsResponse

  goldRate?: GoldRateResponse
}

export const defaultContextState: IContextState = {
  loading: [],
}

interface IContextActions {
  getDetails: () => void

  updateCompanyProfile: (request: MyCompanyProfileRequest) => void

  deleteFile: (fileId: number) => void

  getGoldRate: (currency: string) => void
}

const defaultContextValue: IContext<IContextState, IContextActions> = {
  state: defaultContextState,
  actions: {
    getDetails: DEFAULT_FUNCTION,

    updateCompanyProfile: DEFAULT_FUNCTION,

    deleteFile: DEFAULT_FUNCTION,

    getGoldRate: DEFAULT_FUNCTION,
  },
}

const MyCompanyProfileContext = createContext(defaultContextValue)

export default MyCompanyProfileContext
