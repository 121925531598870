/* eslint-disable react-hooks/exhaustive-deps */
import { PropsWithChildren, useContext, useEffect, useReducer } from 'react'
import reducer from './reducer'
import AuthContext from '../auth/context'
import { defaultContextState } from './context'
import { execute } from '../../utils/helpers/execute'
import BaseService from '../../api/services/base-service'
import {
  CompanyProfileRequest,
  MyCompanyProfileRequest,
} from '../../api/models/company-profile/requests'
import MyCompanyProfileContext from './context'

interface IProps extends PropsWithChildren {}
const MyCompanyProfileContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, defaultContextState)

  const {
    state: { authUser },
  } = useContext(AuthContext)
  /**
   * Get data when query changed
   */
  useEffect(() => {
    if (authUser) {
      getDetails()
    }
  }, [authUser])

  useEffect(() => {
    state.details && getGoldRate(state.details.currency)
  }, [state.details])

  const getGoldRate = async (currency: string) => {
    await execute({
      callback: async () => {
        dispatch({ type: 'LOADING', payload: { loading: 'details' } })

        const { data } = await BaseService.GoldRateService.GoldRate(currency)

        dispatch({ type: 'SET_GOLD_RATE', payload: { goldRate: data } })
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: 'LOADING', payload: { loading: 'details' } })
      },
      throwException: false,
    })
  }

  const getDetails = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: 'LOADING', payload: { loading: 'details' } })

        const { data } = await BaseService.CompanyProfilesService.get()

        dispatch({ type: 'SET_DETAILS', payload: { details: data } })
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: 'LOADING', payload: { loading: 'details' } })
      },
      throwException: false,
    })
  }

  const updateCompanyProfile = async (request: MyCompanyProfileRequest) => {
    await execute({
      callback: async () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'update' },
        })
        await BaseService.CompanyProfilesService.update(request)

        getDetails()
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'update' },
        })
      },
      throwException: true,
    })
  }

  const deleteFile = async (fileId: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'delete-file' },
        })

        await BaseService.CompanyProfilesService.DeleteFile(fileId)

        getDetails()
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'delete-file' },
        })
      },
      throwException: true,
    })
  }

  return (
    <MyCompanyProfileContext.Provider
      value={{
        state,
        actions: {
          getDetails,

          updateCompanyProfile,

          deleteFile,

          getGoldRate,
        },
      }}
    >
      {props.children}
    </MyCompanyProfileContext.Provider>
  )
}

export default MyCompanyProfileContextProvider
