import { createContext } from 'react'
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from '../../utils/helpers/constants'
import { IContext } from '../base/context'
import { SystemUserResponse } from '../../api/models/system-user/responses'
import { SystemUserQuery } from '../../api/models/system-user/query'
import { SystemUserRequest } from '../../api/models/system-user/requests'

export type UserLoading = 'list' | 'details' | 'create' | 'update' | 'delete'

export interface IContextState {
  loading: UserLoading[]

  list?: SystemUserResponse[]
  query: SystemUserQuery

  details?: SystemUserResponse
}

export const defaultContextState: IContextState = {
  loading: [],
  query: DEFAULT_QUERY,
}

interface IContextActions {
  getData: () => void
  getDetails: (id: number) => void
  setDetails: (data?: SystemUserResponse) => void

  createUser: (request: SystemUserRequest) => void
  updateUser: (id: number, request: SystemUserRequest) => void
  deleteUser: (id: number) => void

  setSearch: (search?: string) => void
  setParams: (query: SystemUserQuery) => void
}

const defaultContextValue: IContext<IContextState, IContextActions> = {
  state: defaultContextState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createUser: DEFAULT_FUNCTION,
    updateUser: DEFAULT_FUNCTION,
    deleteUser: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setParams: DEFAULT_FUNCTION,
  },
}

const UserContext = createContext(defaultContextValue)

export default UserContext
