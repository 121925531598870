import { Descriptions as AntdDescriptions } from 'antd'
import { useTranslation } from 'react-i18next'
import { SystemUserResponse } from '../../../api/models/system-user/responses'
import Descriptions from '../../common/antd/descriptions'

interface Props {
  user?: SystemUserResponse
}

const UserDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Descriptions title={t('userDetails')} size='middle' column={1} bordered>
      <AntdDescriptions.Item label={t('id')}>
        {props.user?.id}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('username')}>
        {props.user?.username}
      </AntdDescriptions.Item>
    </Descriptions>
  )
}

export default UserDetails
