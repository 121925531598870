import { useTranslation } from 'react-i18next'
import CompanyProfileInputs from '../inputs'
import { CompanyProfileRequest } from '../../../api/models/company-profile/requests'
import FormModal from '../../common/form/form-modal'
import Upload from '../../common/form/upload'
import { UploadedFile } from '../../../api/models/company-profile/responses'

interface Props {
  loading: boolean
  visable: boolean
  hideModal: () => void
  defaultValues?: CompanyProfileRequest
  onSubmit: (data: CompanyProfileRequest) => void
  uploadedFiles?: UploadedFile[]
}

const CompanyProfileFormModal: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  return (
    <FormModal
      defaultValues={props.defaultValues}
      title={
        props.defaultValues
          ? t('updateCompanyProfile')
          : t('createCompanyProfile')
      }
      onSubmit={props.onSubmit}
      hideModal={props.hideModal}
      confirmLoading={props.loading}
      open={props.visable}
      width={'75vw'}
    >
      <CompanyProfileInputs type={props.defaultValues ? 'edit' : 'create'} />
    </FormModal>
  )
}

export default CompanyProfileFormModal
