/* eslint-disable react-hooks/exhaustive-deps */
import { Form as AntdForm } from 'antd'
import { PropsWithChildren, useEffect } from 'react'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'

interface Props<DataType> {
  defaultValues?: DataType
  onSubmit: (data: any) => void
}

const Form = function <DataType extends FieldValues = any>(
  props: PropsWithChildren<Props<DataType>>
) {
  const methods = useForm<DataType>({
    defaultValues: props.defaultValues as any,
  })

  useEffect(() => {
    if (props.defaultValues) {
      methods.reset({ ...methods.getValues(), ...props.defaultValues })
    }
  }, [props.defaultValues])

  return (
    <FormProvider {...methods}>
      <AntdForm
        labelCol={{ span: 24 }}
        onFinish={methods.handleSubmit(props.onSubmit)}
        scrollToFirstError
      >
        {props.children}
      </AntdForm>
    </FormProvider>
  )
}

export default Form
