import { DirectionType } from 'antd/lib/config-provider'
import { IContextState } from '../app/context';
import { ScreenType } from './context';

type Action =
  | { type: 'SET_DIRECTION'; payload: { direction: DirectionType } }
  | { type: 'SET_LOCALE'; payload: { locale: any } }
  | {
      type: 'SET_SCREEN_TYPE'
      payload: { screenType: ScreenType }
    }

const reducer = (state: IContextState, action: Action): IContextState => {
  switch (action.type) {
    case 'SET_DIRECTION':
      return { ...state, direction: action.payload.direction }
    case 'SET_LOCALE':
      return { ...state, locale: action.payload.locale }
    case 'SET_SCREEN_TYPE':
      return { ...state, screenType: action.payload.screenType }
    default:
      return state
  }
}

export default reducer
