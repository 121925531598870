import { IContextState, UserLoading } from './context'
import paramsReducer, { QueryAction } from './../base/base-reducer'
import { SystemUserResponse } from '../../api/models/system-user/responses'
import { toggleValues } from '../../utils/helper-functions'
import { SystemUserQuery } from '../../api/models/system-user/query'

type Action =
  | { type: 'LOADING'; payload: { loading: UserLoading | UserLoading[] } }
  | { type: 'SET_LIST'; payload: { list: SystemUserResponse[] } }
  | { type: 'SET_DETAILS'; payload: { details?: SystemUserResponse } }
  | QueryAction<SystemUserQuery>

const reducer = (state: IContextState, action: Action): IContextState => {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: toggleValues(state.loading, action.payload.loading),
      }
    case 'SET_LIST':
      return {
        ...state,
        list: action.payload.list,
      }

    case 'SET_DETAILS':
      return {
        ...state,
        details: action.payload.details,
      }

    default:
      return paramsReducer(state, action)
  }
}

export default reducer
