import { Col, Row, Typography } from 'antd'
import moment from 'moment'
import React, { useState, useEffect } from 'react'

const { Text, Title } = Typography

const DigitalClock: React.FC = () => {
  const [currentTime, setCurrentTime] = useState(moment())

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(moment()), 1000)
    return () => clearInterval(timer)
  }, [])

  // const formattedDate = currentTime.toLocaleDateString('en-US', {
  //   weekday: 'long',
  //   year: 'numeric',
  //   month: 'long',
  //   day: 'numeric',
  // })
  // const formattedTime = currentTime.toLocaleTimeString([], {
  //   hour: '2-digit',
  //   minute: '2-digit',
  //   second: '2-digit',
  //   hour12: false,
  // })

  return (
    <Row gutter={[10, 0]}>
      <Col span={24} style={{ lineHeight: '10px' }}>
        <Title level={4} keyboard style={{ margin: 0 }}>
          {currentTime.format('dddd, MMMM Do YYYY')}
        </Title>
        <br />
        <Title level={3} keyboard style={{ margin: 0 }}>
          {currentTime.format('HH:mm:ss A')}
        </Title>
      </Col>
      <Col span={24}></Col>
    </Row>
  )
}

export default DigitalClock
