import { useTranslation } from 'react-i18next'
import MyCompanyProfileInputs from '../inputs'
import { CompanyProfileRequest } from '../../../api/models/company-profile/requests'
import FormModal from '../../common/form/form-modal'
import Upload from '../../common/form/upload'
import { UploadedFile } from '../../../api/models/company-profile/responses'
import { Col, Divider, Row } from 'antd'
import { useContext } from 'react'
import MyCompanyProfileContext from '../../../contexts/my-company-profile/context'
import { FileFor } from '../../../api/models/company-profile/enums'

interface Props {
  loading: boolean
  visable: boolean
  hideModal: () => void
  defaultValues?: CompanyProfileRequest
  onSubmit: (data: CompanyProfileRequest) => void
  uploadedFiles?: UploadedFile[]
}

const MyCompanyProfileFormModal: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const {
    state: { details },
    actions,
  } = useContext(MyCompanyProfileContext)

  return (
    <FormModal
      defaultValues={props.defaultValues}
      title={
        props.defaultValues
          ? t('updateCompanyProfile')
          : t('createCompanyProfile')
      }
      onSubmit={props.onSubmit}
      hideModal={props.hideModal}
      confirmLoading={props.loading}
      open={props.visable}
      width={'75vw'}
    >
      <MyCompanyProfileInputs type={props.defaultValues ? 'edit' : 'create'} />
      <Row>
        <Col span={12}>
          <Divider orientation='left'>{t('logo')}</Divider>
          <Upload
            fileCount={1}
            fileFor={FileFor.Logo}
            uploadUrl={`${process.env.REACT_APP_API_URL}/CompanyProfiles/UPloadFile`}
            onRemove={(id) => {
              actions.deleteFile(id)
              actions.getDetails()
            }}
            fileList={
              details?.uploadedFiles
                ?.filter((f) => f.fileFor == FileFor.Logo)
                .map((file) => {
                  return {
                    uid: file.id.toString(),
                    name: file.fileFor,
                    status: 'done',
                    url: file.url,
                  }
                }) ?? []
            }
          />
        </Col>
        <Col span={12}>
          <Divider orientation='left'>{t('uploads')}</Divider>
          <Upload
            fileCount={5}
            fileFor={FileFor.SliderImage}
            uploadUrl={`${process.env.REACT_APP_API_URL}/CompanyProfiles/UPloadFile`}
            onRemove={(id) => {
              actions.deleteFile(id)
              actions.getDetails()
            }}
            fileList={
              details?.uploadedFiles
                ?.filter((f) => f.fileFor == FileFor.SliderImage)
                .map((file) => {
                  return {
                    uid: file.id.toString(),
                    name: file.fileFor,
                    status: 'done',
                    url: file.url,
                  }
                }) ?? []
            }
          />
        </Col>
      </Row>
    </FormModal>
  )
}

export default MyCompanyProfileFormModal
