import { Avatar, Card, Tooltip } from 'antd'
import { PoweroffOutlined, LockOutlined } from '@ant-design/icons'
import { Fragment, useContext, useState } from 'react'
import AuthContext from '../../../contexts/auth/context'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'
import Button from '../../common/antd/button'
import UserProfileImg from '../../../assets/images/user-profile.svg'

interface Props {}

const AuthUserCard: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { state: authState, actions: authActions } = useContext(AuthContext)

  const [changePasswordModalVisable, setChangePasswordModalVisable] =
    useState(false)

  // const onSubmit = async (data: ChangePasswordRequest) => {
  //   await actions.changePassword(data)
  //   eventManager.emit(EVENT_SUCCESS)
  // }

  return (
    <Fragment>
      <Card
        bordered={false}
        bodyStyle={{ padding: 0 }}
        headStyle={{ padding: 20 }}
        title={
          <Card.Meta
            avatar={<Avatar size={50} src={UserProfileImg} />}
            title={authState.authUser?.name ?? ''}
            description={`#${t(authState.authUser?.role ?? '')}`}
          />
        }
        // extra={
        //   <Tooltip title={t('updateProfile')} placement='bottom'>
        //     <Button shape='circle' icon={<EditOutlined />} />
        //   </Tooltip>
        // }
        actions={[
          <Tooltip title={t('changePassword')}>
            <Button
              className={styles.cardAction}
              type='link'
              onClick={() => setChangePasswordModalVisable(true)}
              size='small'
              icon={<LockOutlined />}
            />
          </Tooltip>,
          <Tooltip title={t('logout')}>
            <Button
              className={styles.cardAction}
              type='link'
              onClick={() => authActions.logout()}
              size='small'
              icon={<PoweroffOutlined />}
              loading={authState.loading.includes('logout')}
            />
          </Tooltip>,
        ]}
      />
      {/* <ChangePasswordModal
        onSubmit={onSubmit}
        visable={changePasswordModalVisable}
        hideModal={() => setChangePasswordModalVisable(false)}
        loading={loading.includes('change-password')}
      /> */}
    </Fragment>
  )
}

export default AuthUserCard
