import { Avatar, Col, Menu, Row, theme } from 'antd'
import { Header as AntHeader } from 'antd/es/layout/layout'
import { useContext } from 'react'
import AppContext from '../../../contexts/app/context'
import { IoLanguageSharp } from 'react-icons/io5'
import i18next from 'i18next'
import AuthContext from '../../../contexts/auth/context'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import AuthUserCard from '../../auth/auth-user-card'
import UserProfileImg from '../../../assets/images/user-profile.svg'

interface IProps {}

const Header: React.FC<IProps> = (props) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  const { t } = useTranslation()

  const { state: appState } = useContext(AppContext)
  const { state: authState } = useContext(AuthContext)

  return (
    <AntHeader className={classNames(styles.header, 'shadow')}>
      {/* {appState.screenType === 'Laptop' ? (
        <Image
          wrapperClassName={classNames(styles.logo)}
          src={`${process.env.REACT_APP_BASE_HOME_PATH}/assets/images/logo-gold-with-text.png`}
          width={300}
        />
      ) : (
        <Image
          wrapperClassName={classNames(styles.logo)}
          src={`${process.env.REACT_APP_BASE_HOME_PATH}/assets/images/logo-gold.png`}
          width={55}
        />
      )} */}
      <Row justify='end'>
        <Col span={24}>
          <Menu
            direction={appState.direction}
            selectable={false}
            mode='horizontal'
            className={styles.menu}
            items={[
              {
                // type:'group',
                key: 'language',
                label: t(i18next.language),
                icon: <IoLanguageSharp />,
                children: [
                  {
                    key: 'langauge:ar',
                    onClick: () => i18next.changeLanguage('ar'),
                    label: t('ar'),
                  },
                  {
                    key: 'langauge:en',
                    onClick: () => i18next.changeLanguage('en'),
                    label: t('en'),
                  },
                ],
              },
              {
                key: 'authUser',
                icon: <Avatar size='default' src={UserProfileImg} />,
                title: authState.authUser?.name,
                className: styles.authUserSubMenu,
                children: [
                  {
                    key: 'authUser:settings',
                    className: styles.authUserSettings,
                    label: <AuthUserCard />,
                  },
                ],
              },
            ]}
          />
          {/* <SubMenu
              key='langauge'
              title={t(i18next.language)}
              icon={<IoLanguageSharp />}
            >
              <Menu.Item
                key='langauge:ar'
                onClick={() => i18next.changeLanguage('ar')}
              >
                {t('ar')}
              </Menu.Item>
              <Menu.Item
                key='langauge:en'
                onClick={() => i18next.changeLanguage('en')}
              >
                {t('en')}
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key='authUser'
              icon={<Avatar size='default' src={UserProfileImg} />}
              title={authState.authUser?.email}
              className={styles.authUserSubMenu}
            >
              <Menu.Item
                key='authUser:settings'
                className={styles.authUserSettings}
              >
                <AuthUserCard />
              </Menu.Item>
            </SubMenu>
          </Menu> */}
        </Col>
      </Row>
    </AntHeader>
  )
}

export default Header
