import { Layout, theme } from 'antd'
import SideBar from '../../side-bar'
import Header from '../../header'
import Footer from '../../footer'
import React, { PropsWithChildren, useState } from 'react'

const { Content } = Layout

interface IProps extends PropsWithChildren {}

const DashboardLayout: React.FC<IProps> = (props) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken()

  const [collapsed, SetCollapsed] = useState(false)

  return (
    <Layout hasSider style={{ minHeight: '100vh' }}>
      <SideBar onCollapse={SetCollapsed} />
      <Layout
        style={{
          marginInlineStart: collapsed ? 81 : 200,
          position: 'relative',
        }}
      >
        <Header />
        <Content
          style={{
            padding: 16,
          }}
        >
          {props.children}
        </Content>
        <Footer />
      </Layout>
    </Layout>
  )
}

export default DashboardLayout
