/* eslint-disable react-hooks/exhaustive-deps */
import { PropsWithChildren, useContext, useEffect, useReducer } from 'react'
import reducer from './reducer'
import AuthContext from '../auth/context'
import CompanyProfileContext, { defaultContextState } from './context'
import { execute } from '../../utils/helpers/execute'
import BaseService from '../../api/services/base-service'
import {
  CompanyProfileDetailsResponse,
  CompanyProfileResponse,
} from '../../api/models/company-profile/responses'
import { CompanyProfileRequest } from '../../api/models/company-profile/requests'
import { CompanyProfileQuery } from '../../api/models/company-profile/query'

interface IProps extends PropsWithChildren {}
const CompanyProfileContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, defaultContextState)

  const {
    state: { authUser },
  } = useContext(AuthContext)
  /**
   * Get data when query changed
   */
  useEffect(() => {
    authUser && getData()
  }, [state.query, authUser])

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: 'LOADING', payload: { loading: 'list' } })

        const { data } =
          await BaseService.CompanyProfilesService.dashboardGetAll(state.query)

        dispatch({ type: 'SET_LIST', payload: { list: data } })
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: 'LOADING', payload: { loading: 'list' } })
      },
      throwException: false,
    })
  }

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: 'LOADING', payload: { loading: 'details' } })

        const { data } =
          await BaseService.CompanyProfilesService.dashboardGetById(id)

        dispatch({ type: 'SET_DETAILS', payload: { details: data } })
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: 'LOADING', payload: { loading: 'details' } })
      },
      throwException: false,
    })
  }

  const setDetails = async (data?: CompanyProfileDetailsResponse) => {
    dispatch({ type: 'SET_DETAILS', payload: { details: data } })
  }

  const createCompanyProfile = async (request: CompanyProfileRequest) => {
    await execute({
      callback: async () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'create' },
        })

        await BaseService.CompanyProfilesService.dashboardCreate(request)

        getData()
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'create' },
        })
      },
      throwException: true,
    })
  }

  const updateCompanyProfile = async (
    id: number,
    request: CompanyProfileRequest
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'update' },
        })
        await BaseService.CompanyProfilesService.dashboardUpdate(id, request)

        getData()
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'update' },
        })
      },
      throwException: true,
    })
  }

  const deleteCompanyProfile = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'delete' },
        })

        await BaseService.CompanyProfilesService.dashboardDelete(id)

        getData()
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'delete' },
        })
      },
      throwException: true,
    })
  }

  const deleteFile = async (id: number, fileId: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'delete-file' },
        })

        await BaseService.CompanyProfilesService.dashboardDeleteFile(id, fileId)

        getDetails(id)
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'delete-file' },
        })
      },
      throwException: true,
    })
  }

  const setSearch = (search?: string) => {
    dispatch({ type: 'SET_SEARCH', payload: { search } })
  }

  const setParams = (query: CompanyProfileQuery) => {
    dispatch({ type: 'SET_QUERY', payload: { query } })
  }

  return (
    <CompanyProfileContext.Provider
      value={{
        state,
        actions: {
          getData,
          getDetails,
          setDetails,

          createCompanyProfile,
          updateCompanyProfile,
          deleteCompanyProfile,

          deleteFile,

          setSearch,
          setParams,
        },
      }}
    >
      {props.children}
    </CompanyProfileContext.Provider>
  )
}

export default CompanyProfileContextProvider
