import { Card } from 'antd'
import { useTranslation } from 'react-i18next'
import AuthLayout from '../../../components/common/layouts/auth-layout'
import Form from '../../../components/common/form/from'
import { execute } from '../../../utils/helpers/execute'
import { LoginRequest } from '../../../api/models/auth/requests'
import { useContext } from 'react'
import AuthContext from '../../../contexts/auth/context'
import SubmitBtn from '../../../components/common/form/submit-btn'
import styles from './styles.module.scss'
import LoginInputs from '../../../components/auth/login-inputs'

interface IProps {}

const Login: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { state, actions } = useContext(AuthContext)

  const onSubmit = async (data: LoginRequest) => {
    await execute({
      callback: async () => {
        await actions.login(data)
      },
      fallback: (error) => {},
      finallyCallback: () => {},
      throwException: false,
    })
  }

  return (
    <AuthLayout>
      <Card title={t('login')} style={{ width: '400' }}>
        <Form<LoginRequest>
          defaultValues={{
            username: '',
            password: '',
          }}
          onSubmit={onSubmit}
        >
          <LoginInputs />
          <SubmitBtn
            className={styles.SubmitBtn}
            loading={state.loading.includes('login')}
          >
            {t('login')}
          </SubmitBtn>
        </Form>
      </Card>
    </AuthLayout>
  )
}

export default Login
