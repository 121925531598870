import {
  Card,
  Carousel,
  Col,
  FloatButton,
  Layout,
  Row,
  Statistic,
  Typography,
} from 'antd'
import WebsiteLayout from '../../../components/common/layouts/website-layouts'
import Image from '../../../components/common/antd/image'
import { useContext, useEffect, useState } from 'react'
import MyCompanyProfileContext from '../../../contexts/my-company-profile/context'
import { UserOutlined, FacebookFilled } from '@ant-design/icons'
import MyCompanyProfileFormModal from '../../../components/my-company-profile/form-modal'
import { MyCompanyProfileRequest } from '../../../api/models/company-profile/requests'
import eventManager, { EVENT_SUCCESS } from '../../../utils/helpers/event'
import MyCompanyProfileContextProvider from '../../../contexts/my-company-profile/provider'
import { GoldTwoTone } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Header } from 'antd/lib/layout/layout'
import { FileFor } from '../../../api/models/company-profile/enums'
import DigitalClock from '../../../components/common/digital-date-time'
import QRCodeCard from '../../../components/common/qr'
import AppContext from '../../../contexts/app/context'
import GoldRateCard from '../../../components/gold-rate/gold-rate-card'
import moment from 'moment'

const { Title } = Typography

interface IProps {}

const WebsiteHome: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const {
    state: { screenType },
  } = useContext(AppContext)

  const {
    state: { details, goldRate, loading },
    actions,
  } = useContext(MyCompanyProfileContext)

  useEffect(() => {
    // Set up the interval to call the function every minute (60000 ms)
    const interval = setInterval(() => {
      details && actions.getGoldRate(details.currency)
    }, 60000)

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval)
  }, [details])

  const onSubmit = async (data: MyCompanyProfileRequest) => {
    details && (await actions.updateCompanyProfile(data))
    eventManager.emit(EVENT_SUCCESS)
  }

  const [formModalVisable, setFormModalVisable] = useState(false)

  return (
    <WebsiteLayout>
      <Header
        className={'shadow'}
        style={{
          backgroundColor: '#fff',
          height: screenType == 'Laptop' ? '100px' : '155px',
          padding: 10,
        }}
      >
        <Row justify={'space-between'}>
          <Col
            span={screenType == 'Laptop' ? 8 : 24}
            order={screenType == 'Laptop' ? 1 : 2}
          >
            <Row gutter={[10, 0]}>
              <Col span={24} style={{ lineHeight: '10px' }}>
                <Title level={4} keyboard style={{ margin: 0 }}>
                  {t('lastUpdate')}:
                </Title>
                <br />
                <Title level={4} keyboard style={{ margin: 0 }}>
                  {goldRate && moment(goldRate.lastUpdate).format('hh:mm A')}
                </Title>
              </Col>
              <Col span={24}></Col>
            </Row>
          </Col>
          <Col
            span={screenType == 'Laptop' ? 8 : 24}
            order={screenType == 'Laptop' ? 2 : 1}
            style={{ textAlign: 'center' }}
          >
            <Image
              width={screenType == 'Laptop' ? 350 : 150}
              src={
                details?.uploadedFiles.find((f) => f.fileFor == FileFor.Logo)
                  ?.url
              }
            />
          </Col>
          <Col
            span={screenType == 'Laptop' ? 8 : 24}
            order={3}
            style={{ textAlign: screenType == 'Laptop' ? 'end' : 'center' }}
          >
            <DigitalClock />
          </Col>
        </Row>
      </Header>
      <FloatButton
        shape='square'
        type='default'
        icon={<UserOutlined />}
        onClick={() => setFormModalVisable(true)}
      />
      <Layout style={{ padding: '15px 15px' }}>
        <Row gutter={[10, 10]}>
          {goldRate && (
            <Col span={screenType == 'Laptop' ? 8 : 24}>
              {screenType == 'Laptop' ? (
                <Row gutter={[10, 10]} justify={'center'}>
                  <Col span={12}>
                    <GoldRateCard
                      title={t('karat24')}
                      value={goldRate.karat24}
                      currency={details?.currency}
                    />
                  </Col>
                  <Col span={12}>
                    <GoldRateCard
                      title={t('karat22')}
                      value={goldRate.karat22}
                      currency={details?.currency}
                    />
                  </Col>
                  <Col span={12}>
                    <GoldRateCard
                      title={t('karat21')}
                      value={goldRate.karat21}
                      currency={details?.currency}
                    />
                  </Col>
                  <Col span={12}>
                    <GoldRateCard
                      title={t('karat18')}
                      value={goldRate.karat18}
                      currency={details?.currency}
                    />
                  </Col>
                  <Col span={24}>
                    <GoldRateCard
                      title={t('ounce')}
                      value={goldRate.ounceInUsd}
                      currency={'USD'}
                      currencySymbol='$'
                    />
                  </Col>
                  {/* <Col span={24}>
                  <Row>
                    <Col>
                      {!details?.facebookPage && (
                        <QRCodeCard
                          title={t('facebookPage')}
                          icon={<FacebookFilled />}
                          value={'dasdad'}
                        />
                      )}
                    </Col>
                  </Row>
                </Col> */}
                </Row>
              ) : (
                <Carousel
                  dotPosition='left'
                  autoplay
                  className='full-size-carousel'
                >
                  <GoldRateCard
                    title={t('karat24')}
                    value={goldRate.karat24}
                    currency={details?.currency}
                  />
                  <GoldRateCard
                    title={t('karat22')}
                    value={goldRate.karat22}
                    currency={details?.currency}
                  />
                  <GoldRateCard
                    title={t('karat21')}
                    value={goldRate.karat21}
                    currency={details?.currency}
                  />
                  <GoldRateCard
                    title={t('karat18')}
                    value={goldRate.karat18}
                    currency={details?.currency}
                  />
                  <GoldRateCard
                    title={t('ounce')}
                    value={goldRate.ounceInUsd}
                    currency={'USD'}
                  />
                </Carousel>
              )}
            </Col>
          )}
          <Col span={screenType == 'Laptop' ? 16 : 24}>
            <Row gutter={[10, 10]} justify={'space-between'}>
              <Col span={24}>
                <Card bordered={false}>
                  <Carousel
                    autoplay
                    className='full-size-carousel'
                    autoplaySpeed={5000}
                  >
                    {details?.uploadedFiles
                      .filter((f) => f.fileFor == FileFor.SliderImage)
                      .map((photo, index) => (
                        <div key={index}>
                          <div
                            style={{
                              backgroundImage: `url(${photo.url})`,
                              backgroundSize: '100% 100%',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              width: '100%',
                              height: '75vh',
                            }}
                          >
                            {/* <Image
                        src={photo.url}
                        style={{
                            width: '100%',
                            height: '60vh',
                            objectFit: 'cover',
                        }} // Adjust if needed based on your layout
                        /> */}
                          </div>
                        </div>
                      ))}
                  </Carousel>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <MyCompanyProfileFormModal
          visable={formModalVisable}
          defaultValues={details as any}
          loading={loading.includes('update')}
          hideModal={() => setFormModalVisable(false)}
          onSubmit={onSubmit}
        />
      </Layout>
    </WebsiteLayout>
  )
}

const WebsiteHomePage: React.FC = (props) => {
  return (
    <MyCompanyProfileContextProvider>
      <WebsiteHome {...props} />
    </MyCompanyProfileContextProvider>
  )
}

export default WebsiteHomePage
