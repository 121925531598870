import { useTranslation } from 'react-i18next'
import UserInputs from '../inputs'
import { SystemUserRequest } from '../../../api/models/system-user/requests'
import FormModal from '../../common/form/form-modal'

interface Props {
  loading: boolean
  visable: boolean
  hideModal: () => void
  defaultValues?: SystemUserRequest
  onSubmit: (data: SystemUserRequest) => void
}

const UserFormModal: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  return (
    <FormModal
      defaultValues={props.defaultValues}
      title={props.defaultValues ? t('updateUser') : t('createUser')}
      onSubmit={props.onSubmit}
      hideModal={props.hideModal}
      confirmLoading={props.loading}
      open={props.visable}
    >
      <UserInputs type={props.defaultValues ? 'edit' : 'create'} />
    </FormModal>
  )
}

export default UserFormModal
