import moment from 'moment'
import { DATE_FORMATE } from './constants'

export const formatDate = (date?: Date, format: string = DATE_FORMATE) => {
  return date && moment(date).format(format)
}

export const a2e = (s: string) =>
  s.replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d).toString())

export const camelize = (s: string) =>
  s.replace(/-./g, (x) => x[1].toUpperCase())

export const getBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file as Blob)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })
