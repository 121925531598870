import { useTranslation } from 'react-i18next'
import { useContext, useState } from 'react'
import UserContextProvider from '../../../contexts/user/provider'
import UserContext from '../../../contexts/user/context'
import UserTable from '../../../components/user/table'
import UserFormModal from '../../../components/user/form-modal'
import UserDetails from '../../../components/user/details'
import { SystemUserRequest } from '../../../api/models/system-user/requests'
import eventManager, { EVENT_SUCCESS } from '../../../utils/helpers/event'
import PageHeader from '../../../components/common/antd/page-header'
import Space from '../../../components/common/antd/space'
import CreateBtn from '../../../components/common/table-components/actions/create-btn'
import RefreshBtn from '../../../components/common/table-components/actions/refresh-btn'
import DetailsModal from '../../../components/common/form/details-modal'
import DashboardLayout from '../../../components/common/layouts/dashboard-layout'

interface Props {}

const Users: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const {
    state: { details, loading },
    actions,
  } = useContext(UserContext)

  const onSubmit = async (data: SystemUserRequest) => {
    details
      ? await actions.updateUser(details.id, data)
      : await actions.createUser(data)
    eventManager.emit(EVENT_SUCCESS)
  }

  const [formModalVisable, setFormModalVisable] = useState(false)

  const [detailsModalVisable, setDetailsModalVisable] = useState(false)

  return (
    <DashboardLayout>
      <PageHeader
        title={t('systemUsers')}
        subTitle={t('manageRecource', { resource: t('systemUsers') })}
        avatar={
          {
            // icon: props.route.icon,
          }
        }
        extra={
          <Space size={0}>
            <CreateBtn
              onClick={() => {
                actions.setDetails(undefined)
                setFormModalVisable(true)
              }}
            />
            <RefreshBtn
              loading={loading.includes('list')}
              onClick={() => actions.getData()}
            >
              {t('refresh')}
            </RefreshBtn>
          </Space>
        }
      />
      <UserTable
        showFormModal={() => setFormModalVisable(true)}
        showDetailsModal={() => setDetailsModalVisable(true)}
      />

      <UserFormModal
        visable={formModalVisable}
        defaultValues={details as any}
        loading={loading.includes('create') || loading.includes('update')}
        hideModal={() => setFormModalVisable(false)}
        onSubmit={onSubmit}
      />

      <DetailsModal
        open={detailsModalVisable}
        hideModal={() => setDetailsModalVisable(false)}
      >
        <UserDetails user={details} />
      </DetailsModal>
    </DashboardLayout>
  )
}

const UsersPage: React.FC<Props> = (props) => {
  return (
    <UserContextProvider>
      <Users {...props} />
    </UserContextProvider>
  )
}
export default UsersPage
