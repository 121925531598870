// axiosInstance.ts
import ApiError from '../models/base/api-error'
import ApiErrorType from '../models/base/api-error-type'
import eventManager, {
  EVENT_ERROR,
  EVENT_FORBIDDEN,
  EVENT_INTERNAL_SERVER_ERROR,
  EVENT_UNAUTHORIZED,
} from '../../utils/helpers/event'
import { HttpClient } from './http-client'
import { ACCEPT_LANGUAGE, LANGUAGE_CODE } from '../../utils/helpers/constants'

// Headers list
let headers: {
  key: string
  value: string | number | boolean
}[] = [
  {
    key: ACCEPT_LANGUAGE,
    value: localStorage.getItem(LANGUAGE_CODE) ?? 'en',
  },
]

export const setAccessToken = (accessToken?: string) => {
  httpClientInstance.setSecurityData({
    token: accessToken,
  })
}

export const setHeader = (key: string, value: string | number | boolean) => {
  // Delete old value
  headers = headers.filter((h) => h.key !== key)

  // set new value
  headers.push({
    key: key,
    value: value,
  })
}

export const setAcceptLanguageHeader = (language: string) => {
  setHeader(ACCEPT_LANGUAGE, language)
}

export const httpClientInstance = new HttpClient<{ token?: string }>({
  securityWorker: (data) => {
    return {
      headers: {
        Authorization: `Bearer ${data?.token ?? ''}`,
      },
    }
  },
})

httpClientInstance.instance.interceptors.request.use(
  (config) => {
    // Add Configs
    headers.forEach((header) => {
      config.headers && (config.headers[header.key] = header.value)
    })

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

httpClientInstance.instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    throw handleError(error)
  }
)

const handleError = (error: ApiError): ApiError => {
  let message: string

  // if (axios.isCancel(error)) return error

  if (error.response) {
    // The request was made and the server responded with an error status code.
    message = error.response.data.message ?? 'something_wrong_message'
    let type: ApiErrorType
    switch (error.response.status) {
      case 400:
        type = ApiErrorType.BAD_REQUEST
        //   toast.error(message)
        break
      case 401:
        type = ApiErrorType.UNAUTHORIZED
        eventManager.emit(EVENT_UNAUTHORIZED)
        break
      case 403:
        type = ApiErrorType.FORBIDDEN
        eventManager.emit(EVENT_FORBIDDEN)
        break
      case 404:
        type = ApiErrorType.NOT_FOUND
        //   toast.error(message)
        break
      case 409:
        type = ApiErrorType.CONFLICT
        break
      case 500:
        type = ApiErrorType.INTERNAL_SERVER_ERROR
        eventManager.emit(EVENT_INTERNAL_SERVER_ERROR)
        // Todo: Remove message from server before deployment
        // toast.error(message)
        break
      default:
        type = ApiErrorType.UNKNOWN
        break
    }

    error.errorType = type
  } else if (error.request) {
    // The request was made but no response was received.
    message = 'something_wrong_message'
    error.errorType = ApiErrorType.CONNECTION
  } else {
    message = 'something_wrong_message'
    error.errorType = ApiErrorType.UNKNOWN
  }

  // Display Error
  // Todo: Clear Log on Production
  console.log('error', message)
  eventManager.emit(EVENT_ERROR, message)

  return error
}
