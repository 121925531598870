import { Path } from '../router/path'

export type MenuItem = {
  path: Path
  labelKey: string
  subMenus?: MenuItem[]
  icon?: JSX.Element
}

export const menuItems: MenuItem[] = [
  { path: '/dashboard', labelKey: 'dashboard' },
  { path: '/dashboard/system-users', labelKey: 'systemUsers' },
  { path: '/dashboard/company-profiles', labelKey: 'companyProfiles' },
]
