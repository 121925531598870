import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import PasswordInput from '../../common/form/inputs/password-input'
import FieldBuilder from '../../common/form/field-builder'
import { Col, Divider, Row } from 'antd'

interface Props {
  type: 'create' | 'edit'
}

const MyCompanyProfileInputs: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <Row gutter={[10, 0]}>
        <Col span={12}></Col>
        <Divider orientation='left'>{t('publicInfo')}</Divider>
        <Col span={12}>
          <FieldBuilder
            name='email'
            label={t('email')}
            input={{ type: 'email' }}
          />
        </Col>
        <Col span={12}>
          <FieldBuilder
            name='phoneNumber'
            label={t('phoneNumber')}
            input={{ type: 'gsm', prefix: '+965' }}
          />
        </Col>
        <Col span={12}>
          <FieldBuilder
            name='whatsAppNumber'
            label={t('whatsAppNumber')}
            input={{ type: 'gsm', prefix: '+965' }}
          />
        </Col>
        <Col span={12}>
          <FieldBuilder
            name='whatsAppChannel'
            label={t('whatsAppChannel')}
            input={{ type: 'url' }}
          />
        </Col>
        <Col span={12}>
          <FieldBuilder
            name='telegramChannel'
            label={t('telegramChannel')}
            input={{ type: 'url' }}
          />
        </Col>
        <Col span={12}>
          <FieldBuilder
            name='facebookPage'
            label={t('facebookPage')}
            input={{ type: 'url' }}
          />
        </Col>
      </Row>
    </Fragment>
  )
}

export default MyCompanyProfileInputs
