import { INITIAL_PAGE } from '../../utils/helpers/constants'

export type QueryAction<T> =
  | { type: 'SET_PAGE'; payload: { page: number } }
  | { type: 'SET_PAGE_SIZE'; payload: { pageSize: number } }
  | { type: 'SET_SEARCH'; payload: { search?: string } }
  | { type: 'SET_QUERY'; payload: { query?: T } }

const queryReducer = <T = any>(state: any, action: QueryAction<T>): any => {
  switch (action.type) {
    case 'SET_PAGE':
      return {
        ...state,
        query: { ...state.query, PageNumber: action.payload.page },
      }
    case 'SET_PAGE_SIZE':
      return {
        ...state,
        query: {
          ...state.query,
          PageNumber: INITIAL_PAGE,
          PageSize: action.payload.pageSize,
        },
      }
    case 'SET_SEARCH':
      return {
        ...state,
        query: {
          ...state.query,
          search: action.payload.search,
          PageNumber: INITIAL_PAGE,
          PageSize: state.query?.PageSize,
        },
      }
    case 'SET_QUERY':
      return {
        ...state,
        query: {
          ...action.payload.query,
          search: state.query?.search,
          PageNumber: INITIAL_PAGE,
          PageSize: state.query?.PageSize,
        },
      }
    default:
      return state
  }
}

export default queryReducer
