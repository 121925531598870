import { Col, Row } from 'antd'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import UsernameInput from '../../common/form/inputs/username-input'
import PasswordInput from '../../common/form/inputs/password-input'
import FieldBuilder from '../../common/form/field-builder'

interface Props {
  type: 'create' | 'edit'
}

const UserInputs: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <FieldBuilder
        name='username'
        label={t('username')}
        input={{ type: 'username' }}
        rules={{ required: true }}
      />
      <PasswordInput required={props.type === 'create'} />
    </Fragment>
  )
}

export default UserInputs
