import { Breadcrumb as AntdBreadcrumb } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { ItemType } from 'antd/lib/breadcrumb/Breadcrumb'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { camelize } from '../../../utils/helpers/functions'
import { menuItems } from '../../../menus/dashboard-menus'
import styles from './styles.module.scss'

interface Props {}

const Breadcrumb: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const location = useLocation()
  const paths: ItemType[] = location.pathname.split('/').map((path) => {
    return {
      path: path,
      breadcrumbName: t(camelize(path)),
    }
  })

  const itemRender = (
    route: ItemType,
    params: any,
    routes: ItemType[],
    paths: string[]
  ) => {
    const menuItem = menuItems.find((m) => m.path === `${paths.join('/')}`)

    if (menuItem) {
      const last = routes.indexOf(route) === routes.length - 1
      return last ? (
        <span className={classNames(styles.breadcrumb, styles.active)}>
          {/* {r?.icon} */}
          <span>{route.breadcrumbName}</span>
        </span>
      ) : (
        <Link to={`${paths.join('/')}`}>
          <span className={styles.breadcrumb}>
            {/* {r?.icon} */}
            <span>{route.breadcrumbName}</span>
          </span>
        </Link>
      )
    }
  }

  return (
    <AntdBreadcrumb
      className={styles.breadcrumbs}
      items={paths}
      separator={null}
      itemRender={itemRender}
    />
  )
}

export default Breadcrumb
