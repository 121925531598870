import { Upload as AntdUpload, UploadFile, UploadProps } from 'antd'
import { useEffect, useState } from 'react'
import { getBase64 } from '../../../../utils/helpers/functions'
import { PlusOutlined } from '@ant-design/icons'
import Modal from '../../antd/modal'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { Exception } from 'sass'
import { FileFor } from '../../../../api/models/company-profile/enums'
import axios, { AxiosRequestConfig } from 'axios'

interface IProps {
  uploadUrl: string
  fileFor: FileFor
  fileCount: number
  onRemove?: (id: number) => void
  fileList: any[]
}
const Upload: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const [cookies] = useCookies()

  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [fileList, setFileList] = useState<UploadFile[]>([])

  useEffect(() => {
    setFileList(props.fileList)
  }, [props.fileList])

  const handleCancel = () => setPreviewOpen(false)

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
    )
  }

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList)

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type='button'>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{t('Upload')}</div>
    </button>
  )

  return (
    <>
      <AntdUpload
        action={props.uploadUrl}
        customRequest={async (options) => {
          const formData = new FormData()
          formData.append('file', options.file as Blob)
          formData.append('fileFor', `${props.fileFor}`)

          const config: AxiosRequestConfig = {
            onUploadProgress: (event) => {
              options.onProgress &&
                options.onProgress({
                  percent: (event.loaded / event.total!) * 100,
                })
            },
            headers: options.headers,
          }

          try {
            const response = await axios.post(options.action, formData, config)
            options.onSuccess && options.onSuccess(response.data)
          } catch (error) {
            if (typeof error === 'string') {
              options.onError && options.onError(new Error(error))
            } else if (error instanceof Error) {
              options.onError && options.onError(error)
            }
          }
        }}
        headers={{
          Authorization: `Bearer ${cookies.accessToken}`,
        }}
        listType='picture-card'
        defaultFileList={props.fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        onRemove={(file) => {
          props.onRemove && props.onRemove(parseInt(file.uid))
          // setFileList(fileList.filter((f) => f.uid !== file.uid))
        }}
      >
        {fileList.length >= props.fileCount ? null : uploadButton}
      </AntdUpload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt='example' style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  )
}

export default Upload
