import { createBrowserRouter } from 'react-router-dom'
import DashboardHome from '../pages/dashboard/home'
import {
  DASHBOARD_HOME_PATH,
  DASHBOARD_LOGIN_Path,
  HOME_Path,
  LOGIN_Path,
} from './path'
import Login from '../pages/auth/login'
import App from '../App'
import UsersPage from '../pages/dashboard/users'
import CompanyProfilesPage from '../pages/dashboard/company-profiles'
import WebsiteHome from '../pages/website/home'

const routes = [
  {
    element: <App />,
    children: [
      //Auth
      {
        path: LOGIN_Path,
        element: <Login />,
      },
      {
        path: DASHBOARD_LOGIN_Path,
        element: <Login />,
      },
      //Website
      {
        path: HOME_Path,
        element: <WebsiteHome />,
      },
      //Dashboard
      {
        path: DASHBOARD_HOME_PATH,
        element: <DashboardHome />,
      },
      {
        path: '/dashboard/system-users',
        element: <UsersPage />,
      },
      {
        path: '/dashboard/company-profiles',
        element: <CompanyProfilesPage />,
      },
    ],
  },
]
const router = createBrowserRouter(routes)

export default router

// export const renderRoute = (
//   route: Route,
//   userPermissions?: Permission[]
// ): any => {
//   return (
//     <DomRoute
//       key={route.path}
//       path={route.path}
//       render={(props) => {
//         if (
//           !route.permissions ||
//           check(userPermissions ?? [], route.permissions)
//         ) {
//           return <route.component {...props} route={route} />
//         }

//         return <Error403 />
//       }}
//       // component={
//       //   !route.permissions || check(userPermissions, route.permissions)
//       //     ? route.component
//       //     : Error403
//       // }
//       exact={route.exact}
//     />
//   )
// }
