/* eslint-disable react-hooks/exhaustive-deps */
import React, { PropsWithChildren, useEffect, useReducer } from 'react'
import en from '../../utils/localization/en/translate.json'
import ar from '../../utils/localization/ar/translate.json'
import AppContext, { defaultContextState } from './context'
import reducer from './reducer'
import { I18nextProvider, useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { ConfigProvider, notification } from 'antd'
import useMediaQuery from 'use-media-antd-query'
import eventManager, { EVENT_ERROR } from '../../utils/helpers/event'
import { errorNotification } from '../../utils/helpers/notification'

const AppContextProvider: React.FC<PropsWithChildren> = (props) => {
  const [state, dispatch] = useReducer(reducer, defaultContextState)

  const screenSize = useMediaQuery()

  useEffect(() => {
    eventManager.on(EVENT_ERROR, (message: string) => {
      errorNotification(message)
    })
  }, [])

  useEffect(() => {
    if (screenSize === 'sm' || screenSize === 'xs') {
      dispatch({ type: 'SET_SCREEN_TYPE', payload: { screenType: 'Mobile' } })
    } else {
      dispatch({ type: 'SET_SCREEN_TYPE', payload: { screenType: 'Laptop' } })
    }
  }, [screenSize])

  /**
   * Langauge
   */
  const {
    i18n: { language },
  } = useTranslation()

  useEffect(() => {
    dispatch({
      type: 'SET_DIRECTION',
      payload: { direction: i18next.dir(language) },
    })

    const locale = language === 'ar' ? ar : en
    dispatch({ type: 'SET_LOCALE', payload: { locale } })
  }, [language])

  useEffect(() => {
    document.body.classList.remove('rtl', 'ltr')
    state.direction && document.body.classList.add(state.direction)

    notification.config({
      placement: state.direction === 'rtl' ? 'bottomLeft' : 'bottomRight',
      rtl: state.direction === 'rtl',
    })
  }, [state.direction])

  return (
    <ConfigProvider
      locale={state.locale}
      direction={state.direction}
      theme={{
        token: {
          colorPrimary: '#BFA55A', // Deep Gold for primary interface elements
          // colorTextSecondary: '#2F4F4F',        // Dark Slate Gray for secondary elements and accents
          colorBgLayout: '#F5F5F5', // Off White for main background
          colorText: '#333333', // Charcoal Black for primary text
          colorTextSecondary: '#606060', // Slightly lighter shade for secondary text
          // '@accent-color': '#806C2E',           // Burnished Bronze for accents and highlights
          colorError: '#8B0000',
        },
      }}
    >
      <I18nextProvider i18n={i18next}>
        <AppContext.Provider
          value={{
            state,
            actions: {},
          }}
        >
          {props.children}
        </AppContext.Provider>
      </I18nextProvider>
    </ConfigProvider>
  )
}

export default AppContextProvider
