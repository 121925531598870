import { HttpClient, RequestParams } from '../http-client/http-client'
import { BaseQuery } from '../models/base/query-param'
import { SystemUserRequest } from '../models/system-user/requests'
import { SystemUserResponse } from '../models/system-user/responses'

class SystemUsersService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAll = (query?: BaseQuery, params?: RequestParams) => {
    return this.http.request<SystemUserResponse[]>({
      path: `/Dashboard/SystemUsers`,
      method: 'GET',
      secure: true,
      query: query,
      ...params,
    })
  }

  create = (data: SystemUserRequest, params?: RequestParams) =>
    this.http.request<SystemUserResponse>({
      path: `/Dashboard/SystemUsers`,
      method: 'POST',
      secure: true,
      body: data,
      ...params,
    })

  update = (id: number, data: SystemUserRequest, params?: RequestParams) =>
    this.http.request<SystemUserResponse>({
      path: `/Dashboard/SystemUsers/${id}`,
      method: 'PUT',
      secure: true,
      body: data,
      ...params,
    })

  delete = (id: number, params?: RequestParams) =>
    this.http.request<void>({
      path: `/Dashboard/SystemUsers/${id}`,
      method: 'DELETE',
      secure: true,
      ...params,
    })
}

export default SystemUsersService
