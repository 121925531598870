import axios from 'axios'
import ApiError, { IErrorData } from '../../api/models/base/api-error'

export const execute = async <T>(parameters: {
  callback: () => Promise<T>
  fallback?: (error: ApiError) => void
  finallyCallback?: () => void
  throwException?: boolean
}): Promise<T | undefined> => {
  try {
    return await parameters.callback()
  } catch (error) {
    if (axios.isCancel(error)) return
    parameters.fallback && parameters.fallback(error as ApiError<IErrorData>)
    if (parameters.throwException) {
      throw error
    }
  } finally {
    parameters.finallyCallback && parameters.finallyCallback()
  }
}
