import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import Form from '../from'
import SubmitBtn from '../submit-btn'
import Modal, { ModalProps } from '../../antd/modal'
import { execute } from '../../../../utils/helpers/execute'
import Button from '../../antd/button'
import { FieldValues } from 'react-hook-form'

interface Props<DataType> extends ModalProps {
  hideModal: () => void
  defaultValues?: DataType
  onSubmit: (data: DataType) => void
}

const FormModal = function <DataType extends FieldValues>({
  hideModal,
  onSubmit,
  defaultValues,
  ...props
}: PropsWithChildren<Props<DataType>>) {
  const { t } = useTranslation()

  const formSubmit = async (data: DataType) => {
    await execute({
      callback: async () => {
        await onSubmit(data)
        hideModal()
      },
      fallback: (error) => {},
      finallyCallback: () => {},
      throwException: false,
    })
  }

  return (
    <Modal
      onCancel={hideModal}
      footer={[
        <Button key='cancle' onClick={() => hideModal()}>
          {t('cancel')}
        </Button>,
        <SubmitBtn key='submit' loading={props.confirmLoading}>
          {t('save')}
        </SubmitBtn>,
      ]}
      modalRender={(node) => {
        return (
          <Form<DataType> onSubmit={formSubmit} defaultValues={defaultValues}>
            {node}
          </Form>
        )
      }}
      {...props}
    />
  )
}

export default FormModal
