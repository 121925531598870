import { HttpClient, RequestParams } from '../http-client/http-client'
import { BaseQuery } from '../models/base/query-param'
import {
  CompanyProfileRequest,
  MyCompanyProfileRequest,
} from '../models/company-profile/requests'
import {
  CompanyProfileDetailsResponse,
  CompanyProfileResponse,
} from '../models/company-profile/responses'

class CompanyProfileService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  get = (params?: RequestParams) => {
    return this.http.request<CompanyProfileDetailsResponse>({
      path: `/CompanyProfiles`,
      method: 'GET',
      secure: true,
      ...params,
    })
  }

  update = (data: MyCompanyProfileRequest, params?: RequestParams) =>
    this.http.request<CompanyProfileResponse>({
      path: `/CompanyProfiles`,
      method: 'PUT',
      secure: true,
      body: data,
      ...params,
    })

  CreateFile = (params?: RequestParams) =>
    this.http.request<void>({
      path: `/CompanyProfiles/UploadFile`,
      method: 'POST',
      secure: true,
      ...params,
    })

  DeleteFile = (fileId: number, params?: RequestParams) =>
    this.http.request<void>({
      path: `/CompanyProfiles/DeleteFile/${fileId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    })

  dashboardGetAll = (query?: BaseQuery, params?: RequestParams) => {
    return this.http.request<CompanyProfileResponse[]>({
      path: `/Dashboard/CompanyProfiles`,
      method: 'GET',
      secure: true,
      query: query,
      ...params,
    })
  }

  dashboardGetById = (id?: number, params?: RequestParams) => {
    return this.http.request<CompanyProfileDetailsResponse>({
      path: `/Dashboard/CompanyProfiles/${id}`,
      method: 'GET',
      secure: true,
      ...params,
    })
  }

  dashboardCreate = (data: CompanyProfileRequest, params?: RequestParams) =>
    this.http.request<CompanyProfileResponse>({
      path: `/Dashboard/CompanyProfiles`,
      method: 'POST',
      secure: true,
      body: data,
      ...params,
    })

  dashboardUpdate = (
    id: number,
    data: CompanyProfileRequest,
    params?: RequestParams
  ) =>
    this.http.request<CompanyProfileResponse>({
      path: `/Dashboard/CompanyProfiles/${id}`,
      method: 'PUT',
      secure: true,
      body: data,
      ...params,
    })

  dashboardDelete = (id: number, params?: RequestParams) =>
    this.http.request<void>({
      path: `/Dashboard/CompanyProfiles/${id}`,
      method: 'DELETE',
      secure: true,
      ...params,
    })

  dashboardCreateFile = (id: number, params?: RequestParams) =>
    this.http.request<void>({
      path: `/Dashboard/CompanyProfiles/${id}/File`,
      method: 'POST',
      secure: true,
      ...params,
    })

  dashboardDeleteFile = (id: number, fileId: number, params?: RequestParams) =>
    this.http.request<void>({
      path: `/Dashboard/CompanyProfiles/${id}/File/${fileId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    })
}

export default CompanyProfileService
