import { EventEmitter } from 'events'

const eventManager = new EventEmitter()

export default eventManager

export const EVENT_SUCCESS = 'success'
export const EVENT_ERROR = 'event-error'
export const EVENT_UNAUTHORIZED = 'event-unauthorized'
export const EVENT_FORBIDDEN = 'event-forbidden'
export const EVENT_INTERNAL_SERVER_ERROR = 'event-internal-server-error'
