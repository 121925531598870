import { useTranslation } from 'react-i18next'
import { useContext, useState } from 'react'
import CompanyProfileContextProvider from '../../../contexts/company-profile/provider'
import CompanyProfileContext from '../../../contexts/company-profile/context'
import CompanyProfileTable from '../../../components/company-profile/table'
import CompanyProfileFormModal from '../../../components/company-profile/form-modal'
import CompanyProfileDetails from '../../../components/company-profile/details'
import { CompanyProfileRequest } from '../../../api/models/company-profile/requests'
import eventManager, { EVENT_SUCCESS } from '../../../utils/helpers/event'
import PageHeader from '../../../components/common/antd/page-header'
import Space from '../../../components/common/antd/space'
import CreateBtn from '../../../components/common/table-components/actions/create-btn'
import RefreshBtn from '../../../components/common/table-components/actions/refresh-btn'
import DetailsModal from '../../../components/common/form/details-modal'
import DashboardLayout from '../../../components/common/layouts/dashboard-layout'
import Upload from '../../../components/common/form/upload'
import { Col, Divider, Row } from 'antd'
import { FileFor } from '../../../api/models/company-profile/enums'

interface Props {}

const CompanyProfiles: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const {
    state: { details, loading },
    actions,
  } = useContext(CompanyProfileContext)

  const onSubmit = async (data: CompanyProfileRequest) => {
    details
      ? await actions.updateCompanyProfile(details.id, data)
      : await actions.createCompanyProfile(data)
    eventManager.emit(EVENT_SUCCESS)
  }

  const [formModalVisable, setFormModalVisable] = useState(false)

  const [detailsModalVisable, setDetailsModalVisable] = useState(false)

  return (
    <DashboardLayout>
      <PageHeader
        title={t('companyProfiles')}
        subTitle={t('manageRecource', { resource: t('companyProfiles') })}
        avatar={
          {
            // icon: props.route.icon,
          }
        }
        extra={
          <Space size={0}>
            <CreateBtn
              onClick={() => {
                actions.setDetails(undefined)
                setFormModalVisable(true)
              }}
            />
            <RefreshBtn
              loading={loading.includes('list')}
              onClick={() => actions.getData()}
            >
              {t('refresh')}
            </RefreshBtn>
          </Space>
        }
      />
      <CompanyProfileTable
        showFormModal={() => setFormModalVisable(true)}
        showDetailsModal={() => setDetailsModalVisable(true)}
      />

      <CompanyProfileFormModal
        visable={formModalVisable}
        defaultValues={details as any}
        loading={loading.includes('create') || loading.includes('update')}
        hideModal={() => setFormModalVisable(false)}
        onSubmit={onSubmit}
      />

      {details && (
        <DetailsModal
          open={detailsModalVisable}
          hideModal={() => setDetailsModalVisable(false)}
          width={'75vw'}
        >
          <CompanyProfileDetails companyProfile={details} />
          <Row>
            <Col span={12}>
              <Divider orientation='left'>{t('logo')}</Divider>
              <Upload
                fileCount={1}
                fileFor={FileFor.Logo}
                uploadUrl={`${process.env.REACT_APP_API_URL}/Dashboard/CompanyProfiles/${details?.id}/File`}
                onRemove={(id) => {
                  actions.deleteCompanyProfile(id)
                }}
                fileList={
                  details?.uploadedFiles
                    ?.filter((f) => f.fileFor == FileFor.Logo)
                    .map((file) => {
                      return {
                        uid: file.id.toString(),
                        name: file.fileFor,
                        status: 'done',
                        url: file.url,
                      }
                    }) ?? []
                }
              />
            </Col>
            <Col span={12}>
              <Divider orientation='left'>{t('uploads')}</Divider>
              <Upload
                fileCount={5}
                fileFor={FileFor.SliderImage}
                uploadUrl={`${process.env.REACT_APP_API_URL}/Dashboard/CompanyProfiles/${details?.id}/File`}
                onRemove={(id) => {
                  actions.deleteCompanyProfile(id)
                }}
                fileList={
                  details?.uploadedFiles
                    ?.filter((f) => f.fileFor == FileFor.SliderImage)
                    .map((file) => {
                      return {
                        uid: file.id.toString(),
                        name: file.fileFor,
                        status: 'done',
                        url: file.url,
                      }
                    }) ?? []
                }
              />
            </Col>
          </Row>
        </DetailsModal>
      )}
    </DashboardLayout>
  )
}

const CompanyProfilesPage: React.FC<Props> = (props) => {
  return (
    <CompanyProfileContextProvider>
      <CompanyProfiles {...props} />
    </CompanyProfileContextProvider>
  )
}
export default CompanyProfilesPage
