import { createContext } from 'react'
import { AuthUser } from '../../api/models/auth/resposes'
import { DEFAULT_FUNCTION } from '../../utils/helpers/constants'
import { LoginRequest } from '../../api/models/auth/requests'
import ApiError, { IErrorData } from '../../api/models/base/api-error'
import { IContext } from '../base/context'

export type AuthLoading = 'login' | 'logout' | 'change-password'

export interface IContextState {
  loading: AuthLoading[]
  authUser?: AuthUser | null
  error?: ApiError<IErrorData>
}

export const defaultContextState: IContextState = {
  loading: [],
}

interface IContextActions {
  login: (request: LoginRequest) => void
  logout: () => void
}

const defaultContextValue: IContext<IContextState, IContextActions> = {
  state: defaultContextState,
  actions: {
    login: DEFAULT_FUNCTION,
    logout: DEFAULT_FUNCTION,
  },
}

const AuthContext = createContext(defaultContextValue)

export default AuthContext
