    import { Footer as AntFooter } from "antd/es/layout/layout";

interface IProps {

}

const Footer: React.FC<IProps> = (props) => {
    return <AntFooter style={{ textAlign: 'center' }}>Ant Design ©2023 Created by Ant UED</AntFooter>
}

export default Footer