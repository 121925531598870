import DashboardLayout from "../../../components/common/layouts/dashboard-layout"

interface IProps {

}

const DashboardHome : React.FC<IProps> = (props) => {
    return <DashboardLayout>
            Dashboard
    </DashboardLayout>
}

export default DashboardHome