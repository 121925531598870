import { PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import Button, { ButtonProps } from '../../../antd/button'

interface Props extends ButtonProps {}

const CreateBtn: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Button
      icon={<PlusOutlined />}
      type='primary'
      title={t('create')}
      {...props}
    >
      {t('create')}
    </Button>
  )
}

export default CreateBtn
