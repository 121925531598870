import { AuthUser } from "../../api/models/auth/resposes"
import { AuthLoading, IContextState } from "./context"
import { toggleValues } from "../../utils/helper-functions"
import ApiError, { IErrorData } from "../../api/models/base/api-error"

type Action =
    | { type: 'LOADING', payload: { loading: AuthLoading | AuthLoading[]  } }
    | { type: 'LOGIN', payload: { user: AuthUser } }
    | { type: 'LOGOUT' }
    | { type: 'ERROR', payload: {error: ApiError<IErrorData>} }

const reducer = (state: IContextState, action: Action): IContextState => {
    switch (action.type) { 
        case 'LOADING':
            return { ...state, loading: toggleValues(state.loading, action.payload.loading) }
        case 'LOGIN':
            return { ...state, authUser: action.payload.user }
        case 'LOGOUT':
            return { ...state, authUser: null }
        case 'ERROR':
            return { ...state, error: action.payload.error}
        default:
            return state;
    }
}

export default reducer