import { Descriptions as AntdDescriptions, Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import { CompanyProfileResponse } from '../../../api/models/company-profile/responses'
import Descriptions from '../../common/antd/descriptions'
import { useEffect } from 'react'
import StatusTag from '../../common/status-tag'

interface Props {
  companyProfile?: CompanyProfileResponse
}

const CompanyProfileDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Descriptions
      title={t('companyProfileDetails')}
      size='middle'
      column={2}
      bordered
    >
      <AntdDescriptions.Item label={t('id')}>
        {props.companyProfile?.id}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('isActive')}>
        <StatusTag boolean value={props.companyProfile?.isActive} />
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={`${t('name')} [عربي]`}>
        {props.companyProfile?.nameAr}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={`${t('name')} [English]`}>
        {props.companyProfile?.nameEn}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={`${t('phoneNumber')} [${t('user')}]`}>
        {props.companyProfile?.userPhoneNumber}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={`${t('username')} [${t('user')}]`}>
        {props.companyProfile?.username}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('phoneNumber')}>
        {props.companyProfile?.phoneNumber}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('email')}>
        {props.companyProfile?.email}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('whatsAppNumber')}>
        {props.companyProfile?.whatsAppNumber}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('whatsAppChannel')}>
        {props.companyProfile?.whatsAppChannel}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('telegramChannel')}>
        {props.companyProfile?.telegramChannel}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('facebookPage')}>
        {props.companyProfile?.facebookPage}
      </AntdDescriptions.Item>
    </Descriptions>
  )
}

export default CompanyProfileDetails
