import { Tooltip } from 'antd'
import { DeleteOutlined, QuestionCircleTwoTone } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import Button, { ButtonProps } from '../../../antd/button'
import Popconfirm from '../../../antd/pop-confirm'

interface Props extends ButtonProps {
  onConfirm: () => void
}

const DeleteBtn: React.FC<Props> = ({ onConfirm, ...props }) => {
  const { t } = useTranslation()

  return (
    <Popconfirm
      title={t('confirmDelete')}
      okButtonProps={{ danger: true, loading: props.loading }}
      icon={<QuestionCircleTwoTone twoToneColor='red' />}
      onConfirm={onConfirm}
    >
      <Tooltip title={t('delete')}>
        <Button
          type='primary'
          danger
          shape='circle'
          icon={<DeleteOutlined />}
          {...props}
        />
      </Tooltip>
    </Popconfirm>
  )
}

export default DeleteBtn
