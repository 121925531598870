// import { IBaseQuery } from 'Data/Models/base/base-query'

export const INITIAL_PAGE = 1
export const DEFAULT_PAGE_SIZE = 20
export const DEFAULT_QUERY = {}
export const DEFAULT_TIMEOUT = 1000
export const DEFAULT_LANGUAGE = 'en'
export const DEFAULT_OTP_DURATION = 2

// export const DEFAULT_QUERY: IBaseQuery = {
//   page: INITIAL_PAGE,
//   perPage: DEFAULT_PAGE_SIZE,
// }

export const DEFAULT_FUNCTION = () => null
export const DEFAULT_ASYNC_FUNCTION = async () => false

export const DATE_FORMATE = 'MMMM Do, YYYY'
export const DATE_TIME_FORMATE = 'MMMM Do, YYYY HH:mm A'

// Regex
export const REGEX_PASSWORD =
  '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$'

export const REGEX_PHONE_NUMBER = '^9\\d{8}$'
export const REGEX_CIF = '^\\d{3,6}$'

// Local Storage Keys
export const LANGUAGE_CODE = 'i18nextLng'
export const THEME_CODE = 'theme'

// Cookies Keys & Configs
export const ACCESS_TOKEN = 'accessToken'
export const OTP_EXPIRED_AT = 'otpExpiredAt'

export const COOKIES_MAX_AGE = 10 * 365 * 24 * 60 * 60 // Years * Days * Hours * Minutes *  Seconds
export const DEFAULT_COOKIES_OPTIONS = {
  secure: true,
  path: '/',
  maxAge: COOKIES_MAX_AGE,
  sameSite: true,
}

// Headers Keys
export const ACCEPT_LANGUAGE = 'Accept-Language'
