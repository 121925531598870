import { Layout } from 'antd'
import { PropsWithChildren } from 'react'

interface IProps extends PropsWithChildren {}

const WebsiteLayout: React.FC<IProps> = (props) => {
  return <Layout style={{ height: '100vh' }}>{props.children}</Layout>
}

export default WebsiteLayout
