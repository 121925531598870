import { ReactNode, useContext } from 'react'
import { Menu as AntdMenu } from 'antd'
import AuthContext from '../../../contexts/auth/context'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import AppContext from '../../../contexts/app/context'
import { ItemType, MenuItemType } from 'antd/lib/menu/hooks/useItems'
import { Path } from '../../../router/path'
import { MenuItem, menuItems } from '../../../menus/dashboard-menus'

const { SubMenu } = AntdMenu

interface Props {}

const Menu: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const {
    state: { direction },
  } = useContext(AppContext)
  const {
    state: {},
  } = useContext(AuthContext)

  const location = useLocation()

  const getOpenKeys = (menuItems: MenuItem[], path: string) => {
    return menuItems.reduce((keys, m) => {
      if (m.subMenus) {
        const openKeys = getOpenKeys(m.subMenus, path)
        if (
          openKeys.length > 0 ||
          m.subMenus.find((sm) => 'path' in sm && sm.path === path)
        ) {
          keys.push(m.labelKey)
        }
        keys.push(...openKeys)
      }
      return keys
    }, [] as string[])
  }

  const getItems = (menuItems: MenuItem[]): ItemType<MenuItemType>[] => {
    return menuItems.reduce((menuItems, current) => {
      if (current.subMenus) {
        menuItems.push({
          type: 'group',
          key: current.path,
          label: (
            <Link to={{ pathname: current.path }}>{t(current.labelKey)}</Link>
          ),
          children: getItems(current.subMenus),
        })
      } else {
        menuItems.push({
          key: current.path,
          icon: current.icon,
          label: (
            <Link to={{ pathname: current.path }}>{t(current.labelKey)}</Link>
          ),
        })
      }

      return menuItems
    }, [] as ItemType<MenuItemType>[])
  }

  return (
    <AntdMenu
      mode='inline'
      theme='dark'
      direction={direction}
      defaultSelectedKeys={[location.pathname]}
      selectedKeys={[location.pathname]}
      defaultOpenKeys={getOpenKeys(menuItems, location.pathname)}
      items={getItems(menuItems)}
    />
  )
}

export default Menu
