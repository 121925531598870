import { httpClientInstance } from '../http-client/http-client-instance'
import AuthService from './auth-service'
import CompanyProfileService from './company-profile-service'
import GoldRateService from './gold-rate-service'
import SystemUsersService from './system-user-service'

class BaseService {
  public static AuthService = new AuthService(httpClientInstance)
  public static CompanyProfilesService = new CompanyProfileService(
    httpClientInstance
  )
  public static SystemUsersService = new SystemUsersService(httpClientInstance)
  public static GoldRateService = new GoldRateService(httpClientInstance)
}

export default BaseService
