import { createContext } from 'react'
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from '../../utils/helpers/constants'
import { IContext } from '../base/context'
import {
  CompanyProfileDetailsResponse,
  CompanyProfileResponse,
} from '../../api/models/company-profile/responses'
import { CompanyProfileQuery } from '../../api/models/company-profile/query'
import { CompanyProfileRequest } from '../../api/models/company-profile/requests'

export type CompanyProfileLoading =
  | 'list'
  | 'details'
  | 'create'
  | 'update'
  | 'delete'
  | 'upload-file'
  | 'delete-file'

export interface IContextState {
  loading: CompanyProfileLoading[]

  list?: CompanyProfileResponse[]
  query: CompanyProfileQuery

  details?: CompanyProfileDetailsResponse
}

export const defaultContextState: IContextState = {
  loading: [],
  query: DEFAULT_QUERY,
}

interface IContextActions {
  getData: () => void
  getDetails: (id: number) => void
  setDetails: (data?: CompanyProfileDetailsResponse) => void

  createCompanyProfile: (request: CompanyProfileRequest) => void
  updateCompanyProfile: (id: number, request: CompanyProfileRequest) => void
  deleteCompanyProfile: (id: number) => void

  deleteFile: (id: number, fileId: number) => void

  setSearch: (search?: string) => void
  setParams: (query: CompanyProfileQuery) => void
}

const defaultContextValue: IContext<IContextState, IContextActions> = {
  state: defaultContextState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createCompanyProfile: DEFAULT_FUNCTION,
    updateCompanyProfile: DEFAULT_FUNCTION,
    deleteCompanyProfile: DEFAULT_FUNCTION,

    deleteFile: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setParams: DEFAULT_FUNCTION,
  },
}

const CompanyProfileContext = createContext(defaultContextValue)

export default CompanyProfileContext
