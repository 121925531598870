import { Layout } from "antd"
import { PropsWithChildren } from "react"
import styles from "./styles.module.scss"

interface IProps extends PropsWithChildren {
}

const AuthLayout: React.FC<IProps> = (props) => {
    return <Layout className={styles.centeredContainer}>
        {props.children}
    </Layout>
}

export default AuthLayout