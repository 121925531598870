/* eslint-disable react-hooks/exhaustive-deps */
import { PropsWithChildren, useEffect, useReducer } from 'react'
import AuthContext, { defaultContextState } from './context'
import reducer from './reducer'
import { LoginRequest } from '../../api/models/auth/requests'
import { execute } from '../../utils/helpers/execute'
import BaseService from '../../api/services/base-service'
import { useCookies } from 'react-cookie'
import {
  ACCESS_TOKEN,
  DEFAULT_COOKIES_OPTIONS,
} from '../../utils/helpers/constants'
import { setAccessToken } from '../../api/http-client/http-client-instance'
import { decodeToken, isExpired } from 'react-jwt'
import { AuthUser, LoginResponse } from '../../api/models/auth/resposes'
import eventManager, {
  EVENT_FORBIDDEN,
  EVENT_SUCCESS,
  EVENT_UNAUTHORIZED,
} from '../../utils/helpers/event'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  DASHBOARD_HOME_PATH,
  DASHBOARD_LOGIN_Path,
  HOME_Path,
  LOGIN_Path,
} from '../../router/path'
import { AxiosResponse } from 'axios'
import { successNotification } from '../../utils/helpers/notification'
import { useTranslation } from 'react-i18next'

const AuthContextProvider: React.FC<PropsWithChildren> = (props) => {
  const { t } = useTranslation()
  const [state, dispatch] = useReducer(reducer, defaultContextState)

  const [cookies, setCookie, removeCookie] = useCookies()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    eventManager.on(EVENT_SUCCESS, (message?: string) => {
      successNotification(message ?? t('operationDoneSuccessfully'))
    })
    eventManager.on(EVENT_UNAUTHORIZED, () => {
      logout()
    })
    eventManager.on(EVENT_FORBIDDEN, () => {
      logout()
    })
  }, [])

  useEffect(() => {
    const token = cookies.accessToken
    if (token && !isExpired(token)) {
      authticateUser(token)
    } else {
      logout()
    }
  }, [])

  const authticateUser = (token: string) => {
    setAccessToken(token)
    setCookie(ACCESS_TOKEN, token, DEFAULT_COOKIES_OPTIONS)

    const user = decodeToken(token) as AuthUser
    dispatch({ type: 'LOGIN', payload: { user } })

    if (location.pathname === DASHBOARD_LOGIN_Path) {
      navigate(DASHBOARD_HOME_PATH)
    } else if (location.pathname === LOGIN_Path) {
      navigate(HOME_Path)
    }
  }

  const login = async (request: LoginRequest) => {
    await execute({
      callback: async () => {
        dispatch({ type: 'LOADING', payload: { loading: 'login' } })

        let result: AxiosResponse<LoginResponse>
        if (location.pathname === DASHBOARD_LOGIN_Path) {
          result = await BaseService.AuthService.DashboardLogin(request)
        } else {
          result = await BaseService.AuthService.userLogin(request)
        }

        const token = result.data.token

        authticateUser(token)
      },
      fallback(error) {
        dispatch({ type: 'ERROR', payload: { error } })
      },
      finallyCallback() {
        dispatch({ type: 'LOADING', payload: { loading: 'login' } })
      },
    })
  }

  const logout = () => {
    removeCookie(ACCESS_TOKEN, DEFAULT_COOKIES_OPTIONS)

    dispatch({ type: 'LOGOUT' })

    if (location.pathname.toLocaleLowerCase().includes('/dashboard')) {
      navigate(DASHBOARD_LOGIN_Path)
    } else {
      navigate(LOGIN_Path)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        state: state,
        actions: {
          login,
          logout,
        },
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
