import { notification } from 'antd'

export const errorNotification = (message: string, description?: string) => {
  return notification.error({
    message: message,
    description: description,
  })
}

export const successNotification = (
  message: string,
  description?: string,
  destroyAllNotificaiton?: boolean
) => {
  destroyAllNotificaiton && notification.destroy()
  return notification.success({
    message: message,
    description: description,
  })
}
