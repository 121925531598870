import { PageHeader as AntdPageHeader, PageHeaderProps } from '@ant-design/pro-layout'
import classNames from 'classnames'
import Breadcrumb from '../../breadcrumb'
import styles from './styles.module.scss'

interface Props extends PageHeaderProps {}

const PageHeader: React.FC<Props> = (props) => {
  return (
    <AntdPageHeader
      // onBack={() => history.goBack()}
      className={classNames('shadow', styles['page-header'])}
      breadcrumbRender={(props) => <Breadcrumb {...props} />}
      {...props}
    />
  )
}

export default PageHeader
