import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import PasswordInput from '../../common/form/inputs/password-input'
import FieldBuilder from '../../common/form/field-builder'

interface Props {}

const LoginInputs: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <FieldBuilder
        name='username'
        label={t('username')}
        input={{ type: 'username' }}
        rules={{ required: true }}
      />
      <PasswordInput />
    </Fragment>
  )
}

export default LoginInputs
