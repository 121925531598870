import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import PasswordInput from '../../common/form/inputs/password-input'
import FieldBuilder from '../../common/form/field-builder'
import { Col, Divider, Row } from 'antd'

interface Props {
  type: 'create' | 'edit'
}

const CompanyProfileInputs: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <Row gutter={[50, 0]}>
        <Col md={{ span: 11 }} sm={{ span: 24 }}>
          <Row gutter={[10, 0]}>
            <Divider orientation='left'>{t('profileInfo')}</Divider>
            <Col span={8}>
              <FieldBuilder
                name='nameAr'
                label={`${t('name')} [عربي]`}
                input={{ type: 'text' }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name='nameEn'
                label={`${t('name')} [English]`}
                input={{ type: 'text' }}
                rules={{ required: true }}
              />
            </Col>

            <Col span={8}>
              <FieldBuilder
                name='currency'
                label={t('currency')}
                input={{ type: 'text' }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={12}>
              <FieldBuilder
                name='userPhoneNumber'
                label={`${t('phoneNumber')} [${t('user')}]`}
                input={{ type: 'gsm', prefix: '+965' }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={12}>
              <FieldBuilder
                name='expiredAt'
                label={t('expiredAt')}
                input={{ type: 'date-picker' }}
              />
            </Col>
          </Row>
          <Row gutter={[10, 0]}>
            <Divider orientation='left'>{t('authInfo')}</Divider>
            <Col span={12}>
              <FieldBuilder
                name='username'
                label={`${t('username')} [${t('user')}]`}
                input={{ type: 'username' }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={12}>
              <PasswordInput required={props.type === 'create'} />
            </Col>
          </Row>
        </Col>
        <Col md={{ span: 1 }} sm={{ span: 0 }}>
          <Divider type='vertical' style={{ height: '100%' }} />
        </Col>
        <Col md={{ span: 11 }} sm={{ span: 24 }}>
          <Row gutter={[10, 0]}>
            <Col span={12}></Col>
            <Divider orientation='left'>{t('publicInfo')}</Divider>
            <Col span={12}>
              <FieldBuilder
                name='email'
                label={t('email')}
                input={{ type: 'email' }}
              />
            </Col>
            <Col span={12}>
              <FieldBuilder
                name='phoneNumber'
                label={t('phoneNumber')}
                input={{ type: 'gsm', prefix: '+965' }}
              />
            </Col>
            <Col span={12}>
              <FieldBuilder
                name='whatsAppNumber'
                label={t('whatsAppNumber')}
                input={{ type: 'gsm', prefix: '+965' }}
              />
            </Col>
            <Col span={12}>
              <FieldBuilder
                name='whatsAppChannel'
                label={t('whatsAppChannel')}
                input={{ type: 'url' }}
              />
            </Col>
            <Col span={12}>
              <FieldBuilder
                name='telegramChannel'
                label={t('telegramChannel')}
                input={{ type: 'url' }}
              />
            </Col>
            <Col span={12}>
              <FieldBuilder
                name='facebookPage'
                label={t('facebookPage')}
                input={{ type: 'url' }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FieldBuilder
                name='isActive'
                label={t('isActive')}
                input={{ type: 'switch' }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  )
}

export default CompanyProfileInputs
