import { useTranslation } from 'react-i18next'
import FieldBuilder from '../../field-builder'

interface Props {
  name?: string
  label?: string
  required?: boolean
}

const PasswordInput: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { required = true } = props

  return (
    <FieldBuilder
      name={props.name ?? 'password'}
      label={props.label ?? t('password')}
      input={{
        type: 'password',
      }}
      rules={{
        minLength: 4,
        maxLength: 15,
        required: required,
        pattern: {
          value:
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/,
        },
      }}
    />
  )
}

export default PasswordInput
