export type Path =
  //Auth
  | '/auth/login'
  | '/dashboard/auth/login'

  //Site
  | '/'

  //Dashboard
  | '/dashboard'
  | '/dashboard/system-users'
  | '/dashboard/company-profiles'

//Auth
export const LOGIN_Path: Path = '/auth/login'
export const DASHBOARD_LOGIN_Path: Path = '/dashboard/auth/login'

//Site
export const HOME_Path: Path = '/'

//Dashboard
export const DASHBOARD_HOME_PATH: Path = '/dashboard'
