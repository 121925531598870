/* eslint-disable react-hooks/exhaustive-deps */
import { PropsWithChildren, useContext, useEffect, useReducer } from 'react'
import reducer from './reducer'
import AuthContext from '../auth/context'
import UserContext, { defaultContextState } from './context'
import { execute } from '../../utils/helpers/execute'
import BaseService from '../../api/services/base-service'
import { SystemUserResponse } from '../../api/models/system-user/responses'
import { SystemUserRequest } from '../../api/models/system-user/requests'
import { SystemUserQuery } from '../../api/models/system-user/query'

interface IProps extends PropsWithChildren {}
const UserContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, defaultContextState)

  const {
    state: { authUser },
  } = useContext(AuthContext)
  /**
   * Get data when query changed
   */
  useEffect(() => {
    authUser && getData()
  }, [state.query, authUser])

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: 'LOADING', payload: { loading: 'list' } })

        const { data } = await BaseService.SystemUsersService.getAll(
          state.query
        )

        dispatch({ type: 'SET_LIST', payload: { list: data } })
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: 'LOADING', payload: { loading: 'list' } })
      },
      throwException: false,
    })
  }

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: 'LOADING', payload: { loading: 'details' } })

        const data = state.list?.find((u) => u.id === id)

        dispatch({ type: 'SET_DETAILS', payload: { details: data } })
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: 'LOADING', payload: { loading: 'details' } })
      },
      throwException: false,
    })
  }

  const setDetails = async (data?: SystemUserResponse) => {
    dispatch({ type: 'SET_DETAILS', payload: { details: data } })
  }

  const createUser = async (request: SystemUserRequest) => {
    await execute({
      callback: async () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'create' },
        })

        await BaseService.SystemUsersService.create(request)

        getData()
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'create' },
        })
      },
      throwException: true,
    })
  }

  const updateUser = async (id: number, request: SystemUserRequest) => {
    await execute({
      callback: async () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'update' },
        })
        await BaseService.SystemUsersService.update(id, request)

        getData()
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'update' },
        })
      },
      throwException: true,
    })
  }

  const deleteUser = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'delete' },
        })

        await BaseService.SystemUsersService.delete(id)

        getData()
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'delete' },
        })
      },
      throwException: true,
    })
  }

  const setSearch = (search?: string) => {
    dispatch({ type: 'SET_SEARCH', payload: { search } })
  }

  const setParams = (query: SystemUserQuery) => {
    dispatch({ type: 'SET_QUERY', payload: { query } })
  }

  return (
    <UserContext.Provider
      value={{
        state,
        actions: {
          getData,
          getDetails,
          setDetails,

          createUser,
          updateUser,
          deleteUser,

          setSearch,
          setParams,
        },
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

export default UserContextProvider
