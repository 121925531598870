import { IContextState, MyCompanyProfileLoading } from './context'
import { QueryAction } from '../base/base-reducer'
import { CompanyProfileDetailsResponse } from '../../api/models/company-profile/responses'
import { toggleValues } from '../../utils/helper-functions'
import { CompanyProfileQuery } from '../../api/models/company-profile/query'
import { GoldRateResponse } from '../../api/models/gold-rate/responses'

type Action =
  | {
      type: 'LOADING'
      payload: { loading: MyCompanyProfileLoading | MyCompanyProfileLoading[] }
    }
  | {
      type: 'SET_DETAILS'
      payload: { details?: CompanyProfileDetailsResponse }
    }
  | {
      type: 'SET_GOLD_RATE'
      payload: { goldRate: GoldRateResponse }
    }
  | QueryAction<CompanyProfileQuery>

const reducer = (state: IContextState, action: Action): IContextState => {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: toggleValues(state.loading, action.payload.loading),
      }
    case 'SET_DETAILS':
      return {
        ...state,
        details: action.payload.details,
      }
    case 'SET_GOLD_RATE':
      return {
        ...state,
        goldRate: action.payload.goldRate,
      }

    default:
      return state
  }
}

export default reducer
