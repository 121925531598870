import { DirectionType } from 'antd/lib/config-provider'
import { createContext } from 'react'
import en from '../../utils/localization/en/translate.json'
import { IContext } from '../base/context'

export type ScreenType = 'Laptop' | 'Mobile'

export interface IContextState {
  screenType: ScreenType
  direction: DirectionType
  locale: any
}

export const defaultContextState: IContextState = {
  screenType: 'Laptop',

  direction: 'ltr',
  locale: en,
}

interface IContextActions {}

const defaultContextValue: IContext<IContextState, IContextActions> = {
  state: defaultContextState,
  actions: {},
}

const AppContext = createContext(defaultContextValue)

export default AppContext
