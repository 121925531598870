import _ from 'lodash';

export function toggleValues<T>(arr: T[], valuesToToggle: T | T[]): T[] {
    let modifiedArray = [...arr];

    // Ensure valuesToToggle is always an array
    const values = Array.isArray(valuesToToggle) ? valuesToToggle : [valuesToToggle];

    values.forEach(value => {
        if (_.includes(modifiedArray, value)) {
            // Value is in the array, remove it
            modifiedArray = _.without(modifiedArray, value);
        } else {
            // Value is not in the array, add it
            modifiedArray = [...modifiedArray, value];
        }
    });

    return modifiedArray;
}
