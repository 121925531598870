import { useContext } from 'react'
import { ColumnProps } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'
import StatusTag from '../../common/status-tag'
import Space from '../../common/antd/space'
import Table from '../../common/table-components/table'
import DeleteBtn from '../../common/table-components/actions/delete-btn'
import EditBtn from '../../common/table-components/actions/edit-btn'
import ViewBtn from '../../common/table-components/actions/view-btn'
import eventManager, { EVENT_SUCCESS } from '../../../utils/helpers/event'
import UserContext from '../../../contexts/user/context'
import { SystemUserResponse } from '../../../api/models/system-user/responses'

interface Props {
  showFormModal: () => void
  showDetailsModal: () => void
}

const UserTable: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const {
    state: { list: users, loading },
    actions,
  } = useContext(UserContext)

  const columns: ColumnProps<SystemUserResponse>[] = [
    {
      key: 'id',
      title: t('id'),
      dataIndex: 'id',
      width: 75,
    },
    {
      key: 'username',
      title: t('username'),
      dataIndex: 'username',
    },
    // {
    //   key: 'isAdmin',
    //   title: t('isAdmin'),
    //   dataIndex: 'isAdmin',
    //   align: 'center',
    //   render: (isAdmin: boolean, record) => {
    //     return (
    //       <Switch
    //         disabled={record.isDefault}
    //         checked={isAdmin}
    //         loading={loading.includes('update')}
    //         onChange={async (checked) => {
    //           await actions.updateUser(record.guid, {
    //             ...record,
    //             isAdmin: checked,
    //           })
    //         }}
    //       />
    //     )
    //   },
    // },
    // {
    //   key: 'isActive',
    //   title: t('isActive'),
    //   dataIndex: 'isActive',
    //   align: 'center',
    //   render: (isActive: boolean, record) => {
    //     return (
    //       <Switch
    //         disabled={record.isDefault}
    //         checked={isActive}
    //         loading={loading.includes('update')}
    //         onChange={async (checked) => {
    //           await actions.updateUser(record.guid, {
    //             ...record,
    //             isActive: checked,
    //           })
    //         }}
    //       />
    //     )
    //   },
    // },
    {
      key: 'actions',
      title: t('actions'),
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Space>
          <ViewBtn
            onClick={() => {
              actions.setDetails(record)
              props.showDetailsModal()
            }}
          />
          <EditBtn
            onClick={() => {
              actions.setDetails(record)
              props.showFormModal()
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteUser(record.id)
              eventManager.emit(EVENT_SUCCESS)
            }}
            loading={loading.includes('delete')}
          />
        </Space>
      ),
    },
  ]

  return (
    <Table<SystemUserResponse>
      rowKey='id'
      columns={columns}
      dataSource={users}
      loading={loading.includes('list')}
    />
  )
}

export default UserTable
