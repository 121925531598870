import { HttpClient, RequestParams } from '../http-client/http-client'
import { LoginResponse } from '../models/auth/resposes'
import { GoldRateResponse } from '../models/gold-rate/responses'

class GoldRateService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  GoldRate = (currency: string, params?: RequestParams) =>
    this.http.request<GoldRateResponse>({
      path: `/GoldRate/${currency}`,
      method: 'GET',
      secure: true,
      ...params,
    })
}

export default GoldRateService
