import { Outlet } from 'react-router-dom'
import AuthContextProvider from './contexts/auth/provider'
import AppContextProvider from './contexts/app/provider'
import './App.css'
import MyCompanyProfileContextProvider from './contexts/my-company-profile/provider'

const App: React.FC = () => {
  return (
    <AppContextProvider>
      <AuthContextProvider>
        {/* <MyCompanyProfileContextProvider> */}
        <Outlet />
        {/* </MyCompanyProfileContextProvider> */}
      </AuthContextProvider>
    </AppContextProvider>
  )
}

export default App
