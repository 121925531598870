import { IContextState, CompanyProfileLoading } from './context'
import paramsReducer, { QueryAction } from '../base/base-reducer'
import {
  CompanyProfileDetailsResponse,
  CompanyProfileResponse,
} from '../../api/models/company-profile/responses'
import { toggleValues } from '../../utils/helper-functions'
import { CompanyProfileQuery } from '../../api/models/company-profile/query'

type Action =
  | {
      type: 'LOADING'
      payload: { loading: CompanyProfileLoading | CompanyProfileLoading[] }
    }
  | { type: 'SET_LIST'; payload: { list: CompanyProfileResponse[] } }
  | {
      type: 'SET_DETAILS'
      payload: { details?: CompanyProfileDetailsResponse }
    }
  | QueryAction<CompanyProfileQuery>

const reducer = (state: IContextState, action: Action): IContextState => {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: toggleValues(state.loading, action.payload.loading),
      }
    case 'SET_LIST':
      return {
        ...state,
        list: action.payload.list,
      }

    case 'SET_DETAILS':
      return {
        ...state,
        details: action.payload.details,
      }

    default:
      return paramsReducer(state, action)
  }
}

export default reducer
