import { useContext } from 'react'
import { ColumnProps } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'
import Space from '../../common/antd/space'
import Table from '../../common/table-components/table'
import DeleteBtn from '../../common/table-components/actions/delete-btn'
import EditBtn from '../../common/table-components/actions/edit-btn'
import ViewBtn from '../../common/table-components/actions/view-btn'
import eventManager, { EVENT_SUCCESS } from '../../../utils/helpers/event'
import CompanyProfileContext from '../../../contexts/company-profile/context'
import { CompanyProfileResponse } from '../../../api/models/company-profile/responses'
import Switch from '../../common/antd/switch'
import { formatDate } from '../../../utils/helpers/functions'
import { DATE_TIME_FORMATE } from '../../../utils/helpers/constants'

interface Props {
  showFormModal: () => void
  showDetailsModal: () => void
}

const CompanyProfileTable: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const {
    state: { list: companyProfiles, loading },
    actions,
  } = useContext(CompanyProfileContext)

  const columns: ColumnProps<CompanyProfileResponse>[] = [
    {
      key: 'id',
      title: t('id'),
      dataIndex: 'id',
      width: 75,
    },
    {
      key: 'name',
      title: t('name'),
      render: (_, record) => {
        return `${record.nameAr} | ${record.nameEn}`
      },
    },
    {
      key: 'username',
      title: `${t('username')} [${t('user')}]`,
      dataIndex: 'username',
    },
    {
      key: 'userPhoneNumber',
      title: `${t('phoneNumber')} [${t('user')}]`,
      dataIndex: 'userPhoneNumber',
    },
    {
      key: 'expiredAt',
      title: t('expiredAt'),
      render: (value) => {
        return formatDate(value)
      },
    },
    {
      key: 'updatedAt',
      title: t('updatedAt'),
      render: (value) => {
        return formatDate(value, DATE_TIME_FORMATE)
      },
    },
    // {
    //   key: 'isAdmin',
    //   title: t('isAdmin'),
    //   dataIndex: 'isAdmin',
    //   align: 'center',
    //   render: (isAdmin: boolean, record) => {
    //     return (
    //       <Switch
    //         disabled={record.isDefault}
    //         checked={isAdmin}
    //         loading={loading.includes('update')}
    //         onChange={async (checked) => {
    //           await actions.updateCompanyProfile(record.guid, {
    //             ...record,
    //             isAdmin: checked,
    //           })
    //         }}
    //       />
    //     )
    //   },
    // },
    {
      key: 'isActive',
      title: t('isActive'),
      dataIndex: 'isActive',
      align: 'center',
      render: (isActive: boolean, record) => {
        return (
          <Switch
            checked={isActive}
            loading={loading.includes('update')}
            onChange={async (checked) => {
              await actions.updateCompanyProfile(record.id, {
                ...record,
                isActive: checked,
              })
            }}
          />
        )
      },
    },
    {
      key: 'actions',
      title: t('actions'),
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Space>
          <ViewBtn
            onClick={() => {
              actions.getDetails(record.id)
              props.showDetailsModal()
            }}
          />
          <EditBtn
            onClick={() => {
              actions.getDetails(record.id)
              props.showFormModal()
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteCompanyProfile(record.id)
              eventManager.emit(EVENT_SUCCESS)
            }}
            loading={loading.includes('delete')}
          />
        </Space>
      ),
    },
  ]

  return (
    <Table<CompanyProfileResponse>
      rowKey='id'
      columns={columns}
      dataSource={companyProfiles}
      loading={loading.includes('list')}
    />
  )
}

export default CompanyProfileTable
