import { useTranslation } from 'react-i18next'
import Modal, { ModalProps } from '../../antd/modal'
import Button from '../../antd/button'

interface Props extends ModalProps {
  hideModal: () => void
}

const DetailsModal: React.FC<Props> = ({ hideModal, ...props }) => {
  const { t } = useTranslation()

  return (
    <Modal
      onCancel={hideModal}
      maskClosable
      footer={[
        <Button key='ok' type='primary' onClick={hideModal}>
          {t('ok')}
        </Button>,
      ]}
      {...props}
    />
  )
}

export default DetailsModal
