import React from 'react'
import Sider from 'antd/es/layout/Sider'
import styles from './styles.module.scss'
import Menu from '../menu'

interface IProps {
  onCollapse?: (value: boolean) => void
}

const SideBar: React.FC<IProps> = (props) => {
  return (
    <Sider
      collapsible
      onCollapse={props.onCollapse}
      breakpoint='lg'
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        top: 0,
        bottom: 0,
      }}
    >
      <div className={styles.logo} />
      <Menu />
    </Sider>
  )
}

export default SideBar
