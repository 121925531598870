import { Table as AntdTable, TableProps } from 'antd'
import { Fragment } from 'react'
import Pagination from '../pagination'

interface Props<RecordType extends object> extends TableProps<RecordType> {
  total?: number
  page?: number
  pageSize?: number
  onPageChange?: (page: number) => void
  onPageSizeChange?: (pageSize: number) => void
  showPagination?: boolean
}

const Table = function <RecordType extends object>({
  onPageChange,
  onPageSizeChange,
  ...props
}: Props<RecordType>) {
  return (
    <Fragment>
      <AntdTable
        className='shadow'
        pagination={false}
        scroll={{ y: '75vh' }}
        {...props}
      />
      {props.showPagination && (
        <Pagination
          total={props.total}
          current={props.page}
          pageSize={props.pageSize}
          onChange={(page) => {
            onPageChange && onPageChange(page)
          }}
          onShowSizeChange={(_, size) => {
            onPageSizeChange && onPageSizeChange(size)
          }}
        />
      )}
    </Fragment>
  )
}

export default Table
